import React, {useState} from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
    const [tab, setTab] = useState('monthly')
    return (
        <Section className="position-relative">
            {/*<ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">*/}
            {/*    <li className={tab === 'monthly' ? "active" : ""} onClick={() => setTab('monthly')}>Monthly</li>*/}
            {/*    <li className={tab === 'yearly' ? "active" : ""} onClick={() => setTab('yearly')}>Yearly</li>*/}
            {/*</ul>*/}
            <Section className="row">

                <h3 className={"mb-5"}>
                    Digital Marketing Packages
                </h3>

                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='Hustler’s'
                            price='178.90'
                            currency='$'
                            timeline='monthly'
                            features={['Static Post: 04', 'GIF (6sec): 02', 'Stories (FB/IG): 02/week', 'GIF Stories: 02', '15 sec Video: 02', 'Monthly Report']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                </Section>
                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='Achiever’s'
                            price='259.99'
                            currency='$'
                            timeline='yearly'
                            features={['Static Post: 06', 'GIF (6sec): 02', 'GIF Stories or SEO Friendly Articles: 02', 'GIF Stories: 02', '15 sec Video: 02 with voice', 'Reels: 02 with voice', 'Monthly Report']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                    <Spacing lg='25' md='25'/>
                </Section>


                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='Magnate’s'
                            price='350.00'
                            currency='$'
                            timeline='monthly'
                            features={['Static Post: 08', 'GIF (6sec): 04', 'Stories (FB/IG): 04/week', 'GIF Stories: 04', '15 sec Video: 02', 'Reels: 02', 'SEO Friendly Articles: 02', 'Monthly Report']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                </Section>
                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='Magnate Boost'
                            price='420.00'
                            currency='$'
                            timeline='yearly'
                            features={['Static Post: 08', 'GIF (6sec): 04', 'Stories (FB/IG): 04/week', 'GIF Stories: 04', '15 sec Video: 04 with voice', 'Reels: 04 with voice', 'SEO Friendly Articles: 02', 'Monthly Report']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                    <Spacing lg='25' md='25'/>
                </Section>
            </Section>

            <Section className="row mt-5">
                <h3 className={"mb-5"}>TikTok Marketing Packages</h3>
                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='4 Videos Pack'
                            price='149.99'
                            currency='$'
                            timeline='monthly'
                            features={['Video Editing', 'Voicing', 'Story Planning', 'Strategy Planning']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                </Section>
                <Section className="col-lg-6">
                    {tab === 'monthly' && (
                        <PricingTable
                            title='8 Videos Pack'
                            price='259.00'
                            currency='$'
                            timeline='yearly'
                            features={['Video Editing', 'Voicing', 'Story Planning', 'Strategy Planning']}
                            btnText='Purchase Now'
                            btnLink='/'
                        />
                    )}
                    <Spacing lg='25' md='25'/>
                </Section>
            </Section>
        </Section>
    )
}
