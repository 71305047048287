import React, {useEffect} from 'react';
import {pageTitle} from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

export default function AboutPage() {
    pageTitle('About');

    const funfaceData = [
        {
            title: 'Global Happy Clients',
            factNumber: '40K',
        },
        {
            title: 'Project Completed',
            factNumber: '50K',
        },
        {
            title: 'Team Members',
            factNumber: '245',
        },
        {
            title: 'Digital products',
            factNumber: '550',
        },
    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* Start Page Heading Section */}
            <PageHeading
                title="About Us"
                bgSrc="images/about_hero_bg.jpeg"
                pageLinkText="About Us"
            />
            {/* End Page Heading Section */}

            {/* Start About Section */}
            <Spacing lg="150" md="80"/>
            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Your trusted partner for business"
                            subtitle="About Our Agency"
                        >
                            <Spacing lg="30" md="20"/>
                            <p className="cs-m0">
                                We are committed to delivering holistic marketing solutions, strategically placing our
                                clients at the forefront of their industries. MODE is not just a service; it is a
                                collaborative partner that navigates you through unique avenues to elevate and enrich
                                your brand. From tailored marketing strategies, MODE caters to a spectrum of business
                                objectives and diverse target audiences.
                            </p>
                            <Spacing lg="30" md="30"/>
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40"/>
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 offset-xl-2">
                        <img
                            src="/images/about_img_1.jpeg"
                            alt="About"
                            className="w-100 cs-radius_15"
                        />
                        <Spacing lg="25" md="25"/>
                    </Div>
                    {/*<Div className="col-lg-7">*/}
                    {/*  <img*/}
                    {/*    src="/images/about_img_2.jpeg"*/}
                    {/*    alt="About"*/}
                    {/*    className="w-100 cs-radius_15"*/}
                    {/*  />*/}
                    {/*  <Spacing lg="25" md="25" />*/}
                    {/*</Div>*/}
                    {/*<Div className="col-lg-5">*/}
                    {/*  <img*/}
                    {/*    src="/images/about_img_3.jpeg"*/}
                    {/*    alt="About"*/}
                    {/*    className="w-100 cs-radius_15"*/}
                    {/*  />*/}
                    {/*  <Spacing lg="25" md="25" />*/}
                    {/*</Div>*/}
                </Div>
            </Div>
            <Spacing lg="75" md="55"/>
            {/* End About Section */}

            {/* Start Fun Fact Section */}
            {/*<Div className="container">*/}
            {/*  <FunFact*/}
            {/*    title="Our fun fact"*/}
            {/*    subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."*/}
            {/*    data={funfaceData}*/}
            {/*  />*/}
            {/*</Div>*/}
            {/* End Fun Fact Section */}

            {/* Start Why Choose Section */}
            <Spacing lg="100" md="80"/>
            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-6">
                        <Div className="cs-image_layer cs-style1">
                            <Div className="cs-image_layer_in">
                                <img
                                    src="/images/about_img_4.jpeg"
                                    alt="About"
                                    className="w-100 cs-radius_15"
                                />
                            </Div>
                        </Div>
                        <Spacing lg="0" md="40"/>
                    </Div>
                    <Div className="col-xl-5 offset-xl-1 col-lg-6">
                        <SectionHeading
                            title="Highly experienced pepole with us"
                            subtitle="Why Choose Us"
                        >
                            <Spacing lg="30" md="20"/>
                            <p className="cs-m0">
                                MODE is your marketing and consultation partner who will guide you through hurdles in
                                the path to brand growth. What you need to know, do and practice in order to elevate
                                your brand will be provided by our experts.
                                The platform in the industry dedicated to your brand will be established and secured in
                                the journey with MODE.
                            </p>
                            <Spacing lg="30" md="30"/>
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="0"/>
                        </SectionHeading>
                    </Div>
                </Div>
            </Div>
            {/* End Why Choose Section */}

            {/* Start Team Section */}
            {/*<Spacing lg="145" md="80"/>*/}
            {/*<Div className="container">*/}
            {/*    <SectionHeading*/}
            {/*        title="Awesome team <br/>members"*/}
            {/*        subtitle="Our Team"*/}
            {/*        variant="cs-style1"*/}
            {/*    />*/}
            {/*    <Spacing lg="85" md="45"/>*/}
            {/*    <TeamSlider/>*/}
            {/*</Div>*/}
            {/* End Team Section */}

            {/* Start CTA Section */}
            <Spacing lg="150" md="80"/>
            <Div className="container">
                <Cta
                    title="Let us make a deal!"
                    btnText="Book a meeting"
                    btnLink="/contact"
                    bgSrc="/images/cta_bg.jpeg"
                />
            </Div>
            {/* End CTA Section */}
        </>
    );
}
