import React from 'react';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';


export default function PaymentErrorPage() {
  return (
    <>
        {/* Start Pricing Section */}
            <Spacing lg="145" md="80" />
            <Div className="container" style={{textAlign: "center"}}>
                <SectionHeading
                title="There is an Error"
                subtitle="Please contact us"
                />
            </Div>
            <Spacing lg="75" md="55" />
        {/* End Pricing Section */}
    </>
  );
}
