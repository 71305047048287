import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {

    const year = new Date().getFullYear()

    const copyrightLinks = [
        {
            title: 'Terms of Use',
            href: '/'
        },
        {
            title: 'Privacy Policy',
            href: '/'
        }
    ]

    const serviceMenu = [
        {
            title: 'Business Consultation',
            href: '#'
        },
        {
            title: 'Digital Marketing',
            href: '#'
        },
        {
            title: 'Digital Solutions',
            href: '#'
        },
        {
            title: 'Music Production',
            href: '#'
        },
        {
            title: 'Photography & Videography',
            href: '#'
        },
        {
            title: 'Printing Services',
            href: '#'
        }
    ]

    return (
        <footer className="cs-fooer">
            <Div className="cs-fooer_main">
                <Div className="container">
                    <Div className="row">
                        <Div className="col-lg-3 col-sm-6">
                            <Div className="cs-footer_item">
                                <TextWidget
                                    logoSrc='/images/logo.png'
                                    logoAlt='Logo'
                                    text='An unwavering focal point where your brand finds dependable partnerships, experiences substantial growth, and achieves undeniable success.'
                                />
                                {/*<SocialWidget/>*/}
                            </Div>
                        </Div>
                        <Div className="col-lg-3 col-sm-6">
                            <Div className="cs-footer_item">
                                <MenuWidget menuItems={serviceMenu} menuHeading='Services'/>
                            </Div>
                        </Div>
                        <Div className="col-lg-3 col-sm-6">
                            <Div className="cs-footer_item">
                                <ContactInfoWidget title='Contact Us'/>
                            </Div>
                        </Div>
                        <Div className="col-lg-3 col-sm-6">
                            <Div className="cs-footer_item">
                                <Newsletter
                                    title='Subscribe'
                                    subtitle='Get the latest news and updates from us.'
                                    placeholder='example@gmail.com'
                                />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
            <Div className="container">
                <Div className="cs-bottom_footer">
                    <Div className="cs-bottom_footer_left">
                        <Div className="cs-copyright">Copyright © {year} MODE | CODUZA.</Div>
                    </Div>
                    {/*<Div className="cs-bottom_footer_right">*/}
                    {/*    <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>*/}
                    {/*</Div>*/}
                </Div>
            </Div>
        </footer>
    )
}
