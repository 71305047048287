import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Home');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Facebook',
      links: '/',
    },
    {
      name: 'Instagram',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
    {
      name: 'Linkedin',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    // {
    //   title: 'Global Happy Clients',
    //   factNumber: '40K',
    // },
    // {
    //   title: 'Project Completed',
    //   factNumber: '50K',
    // },
    // {
    //   title: 'Team Members',
    //   factNumber: '245',
    // },
    // {
    //   title: 'Digital products',
    //   factNumber: '550',
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="The Magical Realm<br/>of Possibility"
        subtitle="The nexus where you and your brand can depend on for partnership, growth and success.  "
        // btnText="Explore Pricing"
        // btnLink="/pay"
        // scrollDownId="#service"
        // socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Hello,"
          subtitle="MODE is your strategic marketing and consultation partner. We guide your brand to reach success and amplify your reach. Your brand is our partner and our primacy. Our focus is centered around empowering businesses to thrive in the digital era."
          // data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What we do"
                btnText="Our Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Business Consultation"
                    link="/service/service-details"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Marketing"
                    link="/service/service-details"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Solutions"
                    link="/service/service-details"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Music Production"
                    link="/service/service-details"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                      title="Photography & Videography"
                      link="/service/service-details"
                      src="/images/service_1.jpeg"
                      alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                      title="Printing Services"
                      link="/service/service-details"
                      src="/images/service_2.jpeg"
                      alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      {/*<Spacing lg="150" md="50" />*/}
      {/*<Div>*/}
      {/*  <Div className="container">*/}
      {/*    <SectionHeading*/}
      {/*      title="Portfolio to explore"*/}
      {/*      subtitle="Latest Projects"*/}
      {/*      variant="cs-style1 text-center"*/}
      {/*    />*/}
      {/*    <Spacing lg="90" md="45" />*/}
      {/*  </Div>*/}
      {/*  <PortfolioSlider />*/}
      {/*</Div>*/}
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/*<Spacing lg="150" md="80" />*/}
      {/*<Div className="cs-shape_wrap_2">*/}
      {/*  <Div className="cs-shape_2">*/}
      {/*    <Div />*/}
      {/*  </Div>*/}
      {/*  <Div className="container">*/}
      {/*    <Div className="row">*/}
      {/*      <Div className="col-xl-4">*/}
      {/*        <SectionHeading*/}
      {/*          title="We get multiple awards"*/}
      {/*          subtitle="Our Awards"*/}
      {/*          variant="cs-style1"*/}
      {/*        />*/}
      {/*        <Spacing lg="90" md="45" />*/}
      {/*      </Div>*/}
      {/*      <Div className="col-xl-7 offset-xl-1">*/}
      {/*        <TimelineSlider />*/}
      {/*      </Div>*/}
      {/*    </Div>*/}
      {/*  </Div>*/}
      {/*</Div>*/}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Digital Presence for your brand
        </h2>
        <p className="text-center">
          Websites, social media, Blog, Web applications, Mobile applications
        </p>
        <h3 className={"text-center mt-5"}>
          Embracing agility is our secret sauce. In our world, change is not just a concept; it is the heartbeat of
          innovation, and the rhythm of growth. Every twist and turn in a dance with change is an opportunity,
          not a foe.
        </h3>
        <p className={"text-center"}>

        </p>
        <Spacing lg="70" md="70" />
        {/*<VideoModal*/}
        {/*  videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"*/}
        {/*  bgUrl="/images/video_bg.jpeg"*/}
        {/*/>*/}
      </Div>
      {/* End Video Block Section */}

      {/* TEAM */}
      {/* Start Team Section */}
      {/*<Spacing lg="145" md="80" />*/}
      {/*<Div className="container">*/}
      {/*  <SectionHeading*/}
      {/*    title="Awesome team <br/>members"*/}
      {/*    subtitle="Our Team"*/}
      {/*    variant="cs-style1"*/}
      {/*  />*/}
      {/*  <Spacing lg="85" md="45" />*/}
      {/*  <TeamSlider />*/}
      {/*</Div>*/}
      {/*<Spacing lg="150" md="80" />*/}
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      {/*<TestimonialSlider />*/}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/*<Spacing lg="150" md="80" />*/}
      {/*<Div className="cs-shape_wrap_4">*/}
      {/*  <Div className="cs-shape_4"></Div>*/}
      {/*  <Div className="cs-shape_4"></Div>*/}
      {/*  <Div className="container">*/}
      {/*    <Div className="row">*/}
      {/*      <Div className="col-xl-4">*/}
      {/*        <SectionHeading*/}
      {/*          title="Explore recent publication"*/}
      {/*          subtitle="Our Blog"*/}
      {/*          btnText="View More Blog"*/}
      {/*          btnLink="/blog"*/}
      {/*        />*/}
      {/*        <Spacing lg="90" md="45" />*/}
      {/*      </Div>*/}
      {/*      <Div className="col-xl-7 offset-xl-1">*/}
      {/*        <Div className="cs-half_of_full_width">*/}
      {/*          <PostSlider />*/}
      {/*        </Div>*/}
      {/*      </Div>*/}
      {/*    </Div>*/}
      {/*  </Div>*/}
      {/*</Div>*/}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      {/*<Spacing lg="125" md="70" />*/}
      {/*<MovingText text="Our reputed world wide partners" />*/}
      {/*<Spacing lg="105" md="70" />*/}
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let us make a deal!"
          btnText="Book a meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
