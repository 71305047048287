import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
    const partnerLogos = [
        {
            src: '/images/partners/1.png',
            alt: 'Partner'
        },
        {
            src: '/images/partners/2.webp',
            alt: 'Partner'
        },
        {
            src: '/images/partners/3.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/4.jpg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/5.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/6.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/7.webp',
            alt: 'Partner'
        },
        {
            src: '/images/partners/8.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/9.png',
            alt: 'Partner'
        },
        {
            src: '/images/partners/10.png',
            alt: 'Partner'
        },
        {
            src: '/images/partners/11.png',
            alt: 'Partner'
        },
        {
            src: '/images/partners/12.webp',
            alt: 'Partner'
        },
        {
            src: '/images/partners/13.png',
            alt: 'Partner'
        },
        {
            src: '/images/partners/14.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/15.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/16.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/17.jpeg',
            alt: 'Partner'
        },
        {
            src: '/images/partners/18.png',
            alt: 'Partner'
        }
    ]
    return (
        <Div className="cs-partner_logo_wrap flex-container">
    {partnerLogos.map((partnerLogo, index) =>
        <div className="cs-partner_logo flex-item" key={index} style={{
            backgroundImage: `url(${partnerLogo.src})`,
        }}>
            {/*<img src={partnerLogo.src} alt={partnerLogo.alt} />*/}
        </div>
    )}
</Div>
    )
}
