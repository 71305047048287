import React from 'react';
import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import PricingTableList from '../PricingTable/PricingTableList';
import { Icon } from '@iconify/react';


export default function PaymentPage() {
  return (
    <>
        {/* Start Pricing Section */}
            <Spacing lg="145" md="80" />
            <Div className="container" style={{textAlign: "center"}}>
                <SectionHeading
                title="Your Payment is Success"
                subtitle="Thank you"
                />
            </Div>
            <Spacing lg="75" md="55" />
        {/* End Pricing Section */}
    </>
  );
}
