import React from 'react';

import axios from 'axios';
import md5 from 'crypto-js/md5';
import {
    API_URL,
    PAYHERE_CANCEL_URL,
    PAYHERE_MERCHANT_ID,
    PAYHERE_NOTIFY_URL,
    PAYHERE_RETURN_URL,
    PAYHERE_URL
} from '../../config/Keys';

import Spacing from '../Spacing';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import PricingTableList from '../PricingTable/PricingTableList';
import {Icon} from '@iconify/react';
import Button from "../Button";


export default function PaymentPage() {

    // states
    const [payData, setPayData] = React.useState({
        currency: '',
        amount: 0,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        order_id: '',
        hash: ''
    });

    // form submit function - new pay
    const handleNewPaySubmit = (e) => {
        e.preventDefault();
        // axios post request
        axios.post(`${API_URL}/customer/new-pay`, {
            name: e.target.name_1.value,
            email: e.target.email_1.value,
            country: e.target.country_1.value,
            tel: e.target.tel_1.value,
            currency: 'USD',
            amount: 1000,
            note: e.target.note_1.value
        }).then((res) => {
            console.log(res);
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    // form submit function - pay
    const handlePaySubmit = () => {
        // e.preventDefault();
        // axios post request
        axios.post(`${API_URL}/customer/pay`, {
            // client_id: e.target.client_id_2.value,
            // currency: e.target.currency_2.value,
            // amount: e.target.amount_2.value,
            // note: e.target.note_2.value

            client_id: document.getElementById('client_id_2').value,
            currency: document.getElementById('currency_2').value,
            amount: document.getElementById('amount_2').value,
            note: document.getElementById('note_2').value
        }).then((res) => {

            console.log(res);
            console.log(res.data);

            setPayData({
                currency: document.getElementById('currency_2').value,
                amount: document.getElementById('amount_2').value,
                first_name: res.data.first_name,
                last_name: res.data.last_name,
                email: res.data.email,
                phone: res.data.phone,
                address: res.data.address,
                city: res.data.city,
                country: res.data.country,
                order_id: res.data._id,
                hash: res.data.hash,
            });

            // const payhere_data = {
            //     merchant_id: PAYHERE_MERCHANT_ID,
            //     return_url: PAYHERE_RETURN_URL,
            //     cancel_url: PAYHERE_CANCEL_URL,
            //     notify_url: PAYHERE_NOTIFY_URL,
            //
            //     first_name: res.data.first_name,
            //     last_name: res.data.last_name,
            //     email: res.data.email,
            //     phone: res.data.phone,
            //     address: res.data.address,
            //     city: res.data.city,
            //     country: res.data.country,
            //     order_id: res.data.order_id,
            //     hash: res.data.hash,
            //
            //     items: "Existing 1",
            //     currency: e.target.currency_2.value,
            //     amount: e.target.amount_2.value
            // }

            // console.log(payhere_data);

            // axios.post(`${PAYHERE_URL}`, payhere_data, {
            //     headers: {
            //         'Access-Control-Allow-Origin': '*',
            //         'Access-Control-Allow-Methods': 'POST',
            //         "Content-Type": "application/x-www-form-urlencoded",
            //     }
            // }).then((res) => {
            //     console.log(res);
            //     console.log(res.data);
            // }).catch((err) => {
            //     console.log(err);
            // });


        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <>

            {/* Start Pricing Section */}
            <Spacing lg="145" md="80"/>
            {/*<Div>*/}
            {/*    <Button btnText={"New Customer"} btnLink={"/new-pay"}/>*/}
            {/*    <Button btnText={"Existing Customer"} btnLink={"/pay"}/>*/}
            {/*</Div>*/}

            <Div className="container">
                <SectionHeading
                    title="Providing best <br/>pricing for client"
                    subtitle="Pricing & Packaging"
                />
                <Spacing lg="85" md="40"/>
                <PricingTableList/>
            </Div>
            <Spacing lg="125" md="55"/>
            {/* End Pricing Section */}


            {/* Start Form Section */}
            {/*<Spacing lg="145" md="80"/>*/}
            {/*<Div className="container">*/}
            {/*    /!* Start Comment Section *!/*/}
            {/*    <Spacing lg='30' md='30'/>*/}
            {/*    <h2 className="cs-font_50 cs-m0">Start your journey with us</h2>*/}
            {/*    <Spacing lg='5' md='5'/>*/}
            {/*    <p className="cs-m0">Your email address will not be published. Required fields are marked *</p>*/}
            {/*    <Spacing lg='40' md='30'/>*/}
            {/*    <form className="row" onSubmit={(e) => handleNewPaySubmit(e)}>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Full Name *</label>*/}
            {/*            <input type="text" name={"name_1"} id={"name_1"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*            <Div data-lastpass-icon-root="true" style={{*/}
            {/*                position: 'relative !important',*/}
            {/*                height: '0px !important',*/}
            {/*                width: '0px !important',*/}
            {/*                float: 'left !important'*/}
            {/*            }}/></Div>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Email *</label>*/}
            {/*            <input type="email" name={"email_1"} id={"email_1"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Country *</label>*/}
            {/*            <input type="text" name={"country_1"} id={"country_1"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Telephone No. *</label>*/}
            {/*            <input type="text" name={"tel_1"} id={"tel_1"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-12">*/}
            {/*            <label>Write Your Note</label>*/}
            {/*            <textarea cols={30} rows={7} name={"note_1"} id={"note_1"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_25 cs-height_lg_25"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-12">*/}
            {/*            <button className="cs-btn cs-style1">*/}
            {/*                <span>Subscribe</span>*/}
            {/*                <Icon icon="bi:arrow-right"/>*/}
            {/*            </button>*/}
            {/*        </Div>*/}
            {/*    </form>*/}
            {/*    /!* End Comment Section *!/*/}
            {/*</Div>*/}
            {/*<Spacing lg="125" md="55"/>*/}
            {/* End Form Section */}


            {/* Start Form Section */}
            {/*<Spacing lg="145" md="80"/>*/}
            {/*<Div className="container">*/}
            {/*    /!* Start Comment Section *!/*/}
            {/*    <Spacing lg='30' md='30'/>*/}
            {/*    <h2 className="cs-font_50 cs-m0">Make your payment easily</h2>*/}
            {/*    <Spacing lg='5' md='5'/>*/}
            {/*    /!* <p className="cs-m0">Your email address will not be published. Required fields are marked *</p> *!/*/}
            {/*    <Spacing lg='40' md='30'/>*/}
            {/*    /!*<form className="row" onSubmit={(e) => handlePaySubmit(e)}>*!/*/}


            {/*    <form className="row" action="https://sandbox.payhere.lk/pay/checkout" method="post">*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Client ID *</label>*/}
            {/*            <input type="text" name={"client_id_2"} id={"client_id_2"} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*            <Div data-lastpass-icon-root="true" style={{*/}
            {/*                position: 'relative !important',*/}
            {/*                height: '0px !important',*/}
            {/*                width: '0px !important',*/}
            {/*                float: 'left !important'*/}
            {/*            }}/></Div>*/}
            {/*        <Div className="col-lg-6"></Div>*/}
            {/*        <Div className="col-lg-12" style={{marginBottom: "16px"}}>*/}
            {/*            <p>Client: ABC LLC.</p>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Currency *</label>*/}
            {/*            <select name={"currency_2"} id={"currency_2"} className="cs-form_field">*/}
            {/*                <option value="USD">USD</option>*/}
            {/*                <option value="LKR">LKR</option>*/}
            {/*                <option value="AUD">AUD</option>*/}
            {/*            </select>*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-6">*/}
            {/*            <label>Amount *</label>*/}
            {/*            <input type="number" name={"amount_2"} id={"amount_2"} className="cs-form_field"/>*/}
            {/*            /!*<input type="number" name={"amount_2"} id={"amount_2"} className="cs-form_field"/>*!/*/}
            {/*            <Div className="cs-height_20 cs-height_lg_20"/>*/}
            {/*        </Div>*/}
            {/*        <Div className="col-lg-12">*/}
            {/*            <label>Write Your Note</label>*/}
            {/*            <textarea name={"note_2"} id={"note_2"} cols={30} rows={7} className="cs-form_field"/>*/}
            {/*            <Div className="cs-height_25 cs-height_lg_25"/>*/}
            {/*        </Div>*/}


            {/*        <input type={"hidden"} value={PAYHERE_MERCHANT_ID} name={"merchant_id"}/>*/}
            {/*        <input type={"hidden"} value={PAYHERE_RETURN_URL} name={"return_url"}/>*/}
            {/*        <input type={"hidden"} value={PAYHERE_CANCEL_URL} name={"cancel_url"}/>*/}
            {/*        <input type={"hidden"} value={PAYHERE_NOTIFY_URL} name={"notify_url"}/>*/}
            {/*        <input type={"hidden"} value={"Existing 1"} name={"items"}/>*/}

            {/*        <input type={"hidden"} value={payData.currency} name={"currency"}/>*/}
            {/*        <input type={"hidden"} value={payData.amount} name={"amount"}/>*/}

            {/*        /!*<input type={"hidden"} value={"Sathnindu"} name={"first_name"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"Kottage"} name={"last_name"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"sathnindu@coduza.com"} name={"email"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"0711427658"} name={"phone"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"345/2, Ddfe, Rrgrthg"} name={"address"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"Colombo"} name={"city"}/>*!/*/}
            {/*        /!*<input type={"hidden"} value={"Sri Lanka"} name={"country"}/>*!/*/}

            {/*        */}
            {/*        <input type={"hidden"} value={payData.first_name} name={"first_name"}/>*/}
            {/*        <input type={"hidden"} value={payData.last_name} name={"last_name"}/>*/}
            {/*        <input type={"hidden"} value={payData.email} name={"email"}/>*/}
            {/*        <input type={"hidden"} value={payData.phone} name={"phone"}/>*/}
            {/*        <input type={"hidden"} value={payData.address} name={"address"}/>*/}
            {/*        <input type={"hidden"} value={payData.city} name={"city"}/>*/}
            {/*        <input type={"hidden"} value={payData.country} name={"country"}/>*/}


            {/*        <input type={"hidden"} value={payData.order_id} name={"order_id"}/>*/}
            {/*        <input type={"hidden"} value={payData.hash} name={"hash"}/>*/}


            {/*        /!*first_name: res.data.first_name,*!/*/}
            {/*        /!*last_name: res.data.last_name,*!/*/}
            {/*        /!*email: res.data.email,*!/*/}
            {/*        /!*phone: res.data.phone,*!/*/}
            {/*        /!*address: res.data.address,*!/*/}
            {/*        /!*city: res.data.city,*!/*/}
            {/*        /!*country: res.data.country,*!/*/}
            {/*        /!*order_id: res.data.order_id,*!/*/}
            {/*        /!*hash: res.data.hash*!/*/}

            {/*        <Div className="col-lg-12">*/}
            {/*            <div className="cs-btn cs-style1" onClick={handlePaySubmit}>*/}
            {/*                <span>Pay now</span>*/}
            {/*                <Icon icon="bi:arrow-right"/>*/}
            {/*            </div>*/}
            {/*            <button className="cs-btn cs-style1" type={"submit"}>*/}
            {/*                <span>Confirm</span>*/}
            {/*                <Icon icon="bi:arrow-right"/>*/}
            {/*            </button>*/}
            {/*        </Div>*/}
            {/*    </form>*/}


            {/*/!*    /!* End Comment Section *!/*!/*/}


            {/*</Div>*/}
            {/*<Spacing lg="125" md="55"/>*/}
            {/* End Form Section */}
        </>
    );
}
